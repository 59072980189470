import React from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { shortcodeCommonLayout } from 'src/components/player/overlays/ShortcodesOverlay/common';
import { TranslatedItem } from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme } from 'src/styles/theme';
import { makeTransition } from 'src/utils/motion/transitions';
import { ShortcodeSingleProduct } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeProducts/ShortcodeSingleProduct';
import { ShortcodeMultiProduct } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeProducts/ShortcodeMultiProduct';
import { media } from 'src/styles/designs';

const ShortcodeProductsContainer = motion(styled.div<{
  $location: string | 'left' | 'right';
  $single: boolean;
}>`
  ${shortcodeCommonLayout};
  ${({ $single }) =>
    !$single &&
    css`
      max-width: 90%;
      overflow: auto;
    `}

  backdrop-filter: blur(12px);
  ${({ $location }) =>
    $location === 'left'
      ? css`
          background: linear-gradient(
            -90deg,
            rgba(16, 16, 16, 0.7) 23.32%,
            rgba(47, 47, 47, 0) 134.98%
          );
        `
      : css`
          background: linear-gradient(
            270deg,
            rgba(16, 16, 16, 0.7) 23.32%,
            rgba(47, 47, 47, 0) 134.98%
          );
        `}
  ${({ $location, $single }) =>
    $location === 'left' &&
    $single &&
    css`
      padding-left: 15px;
      ${media.w.greaterThanOrEqual.desktop} {
        padding-left: 40px;
      }
    `}
`);

export function ShortcodeProducts(props: {
  shortcode: TranslatedItem<Videos_Shortcodes>;
}) {
  const { location, products_products } = props.shortcode;

  if (products_products.length === 0) {
    return null;
  }

  const children =
    products_products.length === 1 ? (
      <ShortcodeSingleProduct
        productPimCode={products_products[0].products_id.pim_code}
      />
    ) : (
      <ShortcodeMultiProduct products={products_products} />
    );

  if (!children) {
    return null;
  }

  return (
    <Theme theme={darkTheme}>
      <ShortcodeProductsContainer
        layout
        {...makeTransition({
          fade: true,
          from: location === 'left' ? 'left' : 'right',
          speed: 'slow',
        })}
        $location={location}
        $single={products_products.length === 1}
        kind="text"
      >
        {children}
      </ShortcodeProductsContainer>
    </Theme>
  );
}
