import styled, { css } from 'styled-components';
import SimpleBar from 'simplebar-react';
import { transparentize } from 'polished';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, forwardRef, Ref, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-hook-inview';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import { StyleProps } from '@summer/jst-react';
import { useTranslate } from 'react-t7e';
import { ChevronBottom } from 'src/components/icons/ChevronBottom';
import { makeTransition } from 'src/utils/motion/transitions';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { media } from 'src/styles/designs';
import { useUpdateEffect } from 'react-use';
import { useDelayedValue } from 'src/hooks/useDelayedValue';

export const ScrollableContent = styled(
  motion(
    forwardRef<
      HTMLElement | null,
      SimpleBar.Props & { innerRef?: Ref<HTMLDivElement | null> }
    >(function ScrollableContent(props, ref) {
      // Replaces ref to a SimpleBar instance with a ref to an HTMLElement, so StyledSimpleBar
      // will be compatible with framer-motion
      const innerRef = useRef<SimpleBar | null>(null);
      useEffect(() => {
        if (typeof ref === 'function') {
          ref(innerRef.current?.el || null);
        } else if (ref) {
          ref.current = innerRef.current?.el || null;
        }
      }, [innerRef.current]);
      return <SimpleBar {...props} ref={innerRef} />;
    }),
  ),
)`
  & > .simplebar-track.simplebar-vertical {
    width: 11rpx;
    right: -6rpx;
    background: none !important;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 6rpx;
      bottom: 0;
      right: 0;
      width: 1rpx;
    }

    .simplebar-scrollbar {
      pointer-events: all;
      width: 11rpx;

      &:before {
        transition: left 0.1s ease-in, width 0.1s ease-in;
        width: 1rpx;
        left: 6rpx;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0;
        opacity: 1;
      }
    }

    &:before {
      background: ${({ theme }) => transparentize(0.7, theme.palette.gray4)};
    }

    .simplebar-scrollbar {
      &:before {
        background: ${({ theme }) => theme.palette.contrastToBackground};
      }
    }
  }

  & > .simplebar-track.simplebar-vertical.simplebar-hover,
  &.simplebar-dragging .simplebar-track.simplebar-vertical {
    .simplebar-scrollbar {
      &:before {
        width: 5rpx;
        left: 4rpx;
      }
    }
  }

  & .simplebar-content {
    // Safari ghost scrollbar fix
    & > :first-child {
      overflow: hidden;
    }
  }
`;

const StyledScrollableContent = styled(ScrollableContent)<{
  $isOverflowingTop: boolean;
  $isOverflowingBottom: boolean;
}>`
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 40px;
    transition: 0.25s;
    pointer-events: none;

    opacity: 0;
    background: linear-gradient(0deg, rgba(51, 51, 51, 0) 0%, #333333 81.47%);
  }

  &::after {
    top: auto;
    bottom: 0;
    background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333333 81.47%);
  }

  ${casePathEmptyString('$isOverflowingTop')(css`
    &::before {
      opacity: 0.7;
    }
  `)}

  ${casePathEmptyString('$isOverflowingBottom')(css`
    &::after {
      opacity: 0.7;
    }
  `)}
`;

export interface ShadowedScrollableContentProps extends StyleProps {
  indicator?: boolean;
}

const Indicator = styled(motion.span).attrs(makeTransition({ fade: true }))`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 6px 12px;

  border-radius: 30px;
  background: #000;
  color: ${({ theme }) => theme.text.palette.primary};
`;

export const ShadowedScrollableContent: FC<ShadowedScrollableContentProps> = ({
  children,
  indicator = true,
  ...props
}) => {
  const { _ } = useTranslate();
  const isMobile = useMediaQuery(media.w.lessThan.desktop);

  const [intersectionTopRef, isTopVisible] = useInView();
  const [intersectionBottomRef, isBottomVisible] = useInView();

  const [indicatorVisible, setIndicatorVisible] = useState(
    indicator && !isBottomVisible,
  );

  useUpdateEffect(() => {
    if (indicatorVisible && (!isTopVisible || isBottomVisible)) {
      setIndicatorVisible(false);
    }
  }, [isTopVisible, isBottomVisible]);

  const indicatorVisibleDelayed = useDelayedValue(indicatorVisible, 500, false);

  return (
    <StyledScrollableContent
      $isOverflowingTop={!isTopVisible}
      $isOverflowingBottom={!isBottomVisible}
      {...props}
    >
      <div ref={intersectionTopRef} />

      {children}

      <AnimatePresence>
        {indicatorVisibleDelayed && indicatorVisible && (
          <Indicator>
            <ChevronBottom size={11} /> {_(isMobile ? 'SWIPE' : 'SCROLL')}
          </Indicator>
        )}
      </AnimatePresence>
      <div ref={intersectionBottomRef} />
    </StyledScrollableContent>
  );
};
