import { css } from 'styled-components';
import { media } from 'src/styles/designs';
import { fromTheme } from 'src/styles/theme';

export const defaultDuration = 5;

export const durationByType: Record<string, number | undefined> = {
  contact: defaultDuration,
  link: defaultDuration,
  label: defaultDuration,
  video: defaultDuration,
};

export const shortcodeCommonLayout = css<{
  $location: string | 'left' | 'right';
}>`
  position: absolute;
  bottom: 70px;
  font-family: ${fromTheme((a) => a.text.font.primary)};
  ${({ $location }) =>
    $location === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}

  ${media.w.lessThan.desktop} {
    bottom: 49px;
  }
`;
