import React, { HTMLAttributes, useMemo, useReducer } from 'react';
import {
  TranslatedItem,
  useTranslateItem,
} from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { Observable } from '@hitorisensei/kefir-atomic';
import {
  defaultDuration,
  durationByType,
} from 'src/components/player/overlays/ShortcodesOverlay/common';
import { useStream } from 'vendor/hooks/useStream';
import { head, sort, uniq } from 'rambda';
import { AnimatePresence } from 'framer-motion';
import { ShortcodeDiscriminator } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeDiscriminator';
import { ModalControllerContext } from 'src/components/common/ModalControllerContext';
import { playerFadeZIndex, OverlayLayer } from 'src/components/player/common';
import { withSilentErrorBoundary } from 'src/utils/WithSilentErrorBoundary';

/**
 * Parse duration in hh:mm:ss and return duration in seconds
 * @param hhmmss
 */
const toSeconds = (hhmmss: string): number => {
  const [hours, minutes, seconds] = hhmmss
    .padStart(8, '00:')
    .split(':')
    .map((v) => parseInt(v));
  return seconds + minutes * 60 + hours * 60 * 60;
};
const Shortcode = withSilentErrorBoundary(
  ({ shortcode }: { shortcode: TranslatedItem<Videos_Shortcodes> }) => {
    return <ShortcodeDiscriminator shortcode={shortcode} />;
  },
);

const shortcodesLayerZIndex = playerFadeZIndex - 2;

export const ShortcodesOverlay = ({
  time$,
  shortcodes,
  ...props
}: {
  shortcodes: Videos_Shortcodes[];
  time$: Observable<number, unknown>;
} & HTMLAttributes<HTMLDivElement>) => {
  const [closedShortcodes, closeShortcode] = useReducer(
    (closedIds: Array<string>, shortcodeIdToClose: string) =>
      uniq([...closedIds, shortcodeIdToClose]),
    [],
  );
  const translate = useTranslateItem();
  const mappedShortcodes = useMemo(
    () =>
      shortcodes
        .filter((shortcode) => !closedShortcodes.includes(shortcode.id))
        .map(translate)
        .map((shortcode) => {
          const startTime = toSeconds(shortcode.display_time);
          const endTime = shortcode.hide_time
            ? toSeconds(shortcode.hide_time)
            : startTime + (durationByType[shortcode.type] || defaultDuration);
          return {
            startTime,
            endTime,
            shortcode,
          };
        }),
    [translate, shortcodes, closedShortcodes],
  );
  const currentShortcode = useStream(
    () =>
      time$.map((time) => {
        const shortcodesInMoment = mappedShortcodes.filter(
          (shortcode) =>
            shortcode.startTime <= time && time <= shortcode.endTime,
        );

        return head(
          sort(
            (x, y) => (x.startTime < y.startTime ? 1 : -1),
            shortcodesInMoment,
          ),
        );
      }),
    undefined,
    [time$, mappedShortcodes],
  );

  return (
    <OverlayLayer
      {...props}
      // place shortcodes under the share fade
      style={{
        zIndex: shortcodesLayerZIndex,
      }}
    >
      <AnimatePresence exitBeforeEnter>
        {currentShortcode && (
          <ModalControllerContext.Provider
            key={currentShortcode.shortcode.id}
            value={{
              close: (e) => {
                e?.preventDefault();
                e?.stopPropagation();
                closeShortcode(currentShortcode.shortcode.id);
              },
            }}
          >
            <Shortcode shortcode={currentShortcode.shortcode} />
          </ModalControllerContext.Provider>
        )}
      </AnimatePresence>
    </OverlayLayer>
  );
};
