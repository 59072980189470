import { TranslatedItem } from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme } from 'src/styles/theme';
import { makeTransition } from 'src/utils/motion/transitions';
import { ChevronRight } from 'src/components/icons/ChevronRight';
import React from 'react';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Button } from 'src/components/common/Button';
import { shortcodeCommonLayout } from 'src/components/player/overlays/ShortcodesOverlay/common';
import { media } from 'src/styles/designs';

const ShortcodeLinkContainer = motion(styled(Button)<{
  $location: string | 'left' | 'right';
}>`
  && {
    ${shortcodeCommonLayout};
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0;
    color: ${({ theme }) => theme.text.palette.primary};
    backdrop-filter: blur(12px);
    padding: 14px 20px 14px 25px;

    ${({ $location }) =>
      $location === 'left'
        ? css`
            background: linear-gradient(
              -90deg,
              rgba(16, 16, 16, 0.7) 23.32%,
              rgba(47, 47, 47, 0) 134.05%
            );
          `
        : css`
            background: linear-gradient(
              270deg,
              rgba(16, 16, 16, 0.7) 23.32%,
              rgba(47, 47, 47, 0) 134.05%
            );
          `}

    svg {
      margin-left: 14px;
      height: 24px;
      width: 24px;
    }

    ${media.w.lessThan.desktop} {
      font-size: 10px;
      line-height: 12px;
      padding: 9px;

      svg {
        margin-left: 14px;
        height: 16px;
        width: 16px;
      }
    }
  }
`);

export function ShortcodeLink(props: {
  shortcode: TranslatedItem<Videos_Shortcodes>;
}) {
  const { link_url, link_title, location } = props.shortcode;
  return (
    <a target="_blank" href={link_url} rel="noreferrer">
      <Theme theme={darkTheme}>
        <ShortcodeLinkContainer
          layout
          {...makeTransition({
            fade: true,
            from: location === 'left' ? 'left' : 'right',
            speed: 'slow',
          })}
          $location={location}
          kind="text"
        >
          {link_title}
          <ChevronRight />
        </ShortcodeLinkContainer>
      </Theme>
    </a>
  );
}
