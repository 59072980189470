import { memo, useMemo } from 'react';
import { TrackedLink } from 'src/components/TrackedLink';
import styled from 'styled-components';
import { dirRTL, fromTheme } from 'src/styles/theme';
import { useTranslate } from 'react-t7e';
import { Button } from 'src/components/common/Button';
import { motion } from 'framer-motion';
import { media } from 'src/styles/designs';
import { Videos } from 'src/graphql/schema.graphql';
import { ChevronLeft } from 'src/components/icons/ChevronLeft';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { OverlayLayer } from 'src/components/player/common';
import { makeTransition } from 'src/utils/motion/transitions';
import { useSelector } from 'react-redux';
import { Route } from 'src/services/route';

const VideoTitleContainer = styled(OverlayLayer)`
  position: absolute;
  top: 16px;
  left: 16px;
  right: 0;
  max-width: 60%;
  pointer-events: none;

  ${media.w.greaterThanOrEqual.desktop} {
    top: 20px;
    left: 40px;
  }

  opacity: 1;
  transition: opacity ${fromTheme((t) => t.animation.duration)}s
    ${fromTheme((t) => t.animation.easing.accelerated)};
`;

const StyledMotionDiv = styled(motion.div)`
  pointer-events: none !important;
`;

const BackButton = styled(Button)`
  margin-bottom: 12px;
  pointer-events: all;
  ${dirRTL} {
    direction: rtl !important;
  }
`;

const VideoTitle = styled(motion.h1)`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  pointer-events: none !important;

  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.text.palette.heavy};

  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

const transition = makeTransition({ fade: true, speed: 'faster' });

export interface TitleOverlayProps {
  video: Videos;
  backHref?: string | boolean;
}

const backHrefConfigMap: Record<string, { title: string; href: string }> = {
  [Route.VideosMain]: {
    title: `Back to Medical Education`,
    href: Route.VideosMain,
  },
  [Route.ExpertZoneMain]: {
    title: `Back to files list`,
    href: Route.ExpertZoneMain,
  },
};

export const TitleOverlay = memo(({ video, backHref }: TitleOverlayProps) => {
  const { _ } = useTranslate();
  const translateItem = useTranslateItem();
  const isDesktop = useMediaQuery(media.w.greaterThanOrEqual.desktop);

  const previous = useSelector((s) => s.app.previousRoute);

  const back = useMemo(() => {
    if (backHref) {
      if (typeof backHref === 'string') {
        return {
          ...backHrefConfigMap[Route.VideosMain],
          href: backHref,
        };
      }

      return (
        (previous && backHrefConfigMap[previous.route]) ??
        backHrefConfigMap[Route.VideosMain]
      );
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backHref]);

  return (
    <VideoTitleContainer>
      <StyledMotionDiv {...transition}>
        {back && (
          <TrackedLink href={back.href} passHref>
            <BackButton
              forwardedAs="a"
              kind="rounded"
              size={isDesktop ? 'default' : 'small'}
              icon={ChevronLeft}
            >
              {_(back.title)}
            </BackButton>
          </TrackedLink>
        )}
        <VideoTitle>{translateItem(video).name}</VideoTitle>
      </StyledMotionDiv>
    </VideoTitleContainer>
  );
});

TitleOverlay.displayName = 'TitleOverlay';
