import React, { memo, useCallback, useEffect, useState } from 'react';
import { UnmuteOverlay } from 'src/components/player/overlays/UnmuteOverlay';
import { Videos } from 'src/graphql/schema.graphql';
import { UserActionsOverlay } from 'src/components/player/overlays/UserActionsOverlay';
import { ShareOverlay } from 'src/components/player/overlays/ShareOverlay';
import { TitleOverlay } from 'src/components/player/overlays/TitleOverlay';
import { VideoSummaryOverlay } from 'src/components/player/overlays/VideoSummaryOverlay';
import { darkTheme } from 'src/styles/theme';
import { Theme } from 'vendor/mods/styled-components';
import { HealthcareLegalConsentOverlay } from 'src/components/player/overlays/HealthcareLegalConsentOverlay';
import { useUserHealthcareLegalConsent } from 'src/hooks/useUserHealthcareLegalConsent';
import { Observable } from '@hitorisensei/kefir-atomic';
import { ShortcodesOverlay } from 'src/components/player/overlays/ShortcodesOverlay';
import { RelatedVideosOverlay } from 'src/components/player/overlays/RelatedVideosOverlay';
import { AnimatePresence } from 'framer-motion';
import { MiniplayerActionsOverlay } from 'src/components/player/overlays/MiniplayerActionsOverlay';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { media } from 'src/styles/designs';
import { canUserAccessItem } from 'src/modules/user/utils/permissions';
import { VideoFormat } from 'src/modules/videos/models';
import { ContactButtonOverlay } from 'src/components/player/overlays/ContactButtonOverlay';
import { MdrDisclaimerOverlay } from 'src/components/player/overlays/MdrDisclaimerOverlay';

export interface OverlayControls {
  userActions?: {
    enabled: boolean;
    share?: boolean;
    miniplayer?: boolean;
  };
  relatedVideos?: {
    enabled: boolean;
  };
  shortcodes?: {
    enabled: boolean;
  };
  summary?: {
    enabled: boolean;
  };
  title?: {
    enabled: boolean;
    backHref?: string | boolean;
  };
  miniplayerActions?: {
    enabled: boolean;
    onReturn: () => void;
    onClose: () => void;
  };
  healthcareConsent?: {
    enabled: boolean;
    backgroundOnly?: boolean;
    onBackgroundClick?: () => void;
  };
  mdrDisclaimer?: {
    enabled: boolean;
  };
}

export interface OverlayControllerProps {
  controls?: OverlayControls;

  video: Videos;

  ready: boolean;
  playing: boolean;
  muted: boolean;
  unmutedOnce: boolean;
  fullscreen: boolean;
  time$: Observable<number, unknown>;
  duration: number;
  miniplayer: boolean;

  onPlay: () => void;
  onPause: () => void;
  onUnmute: () => void;
  onOpenMiniplayer: () => void;
}

export const OverlayController = memo<OverlayControllerProps>(
  function OverlayController({
    controls,
    video,
    ready,
    playing,
    muted,
    unmutedOnce,
    fullscreen,
    miniplayer,
    time$,
    duration,
    onPlay,
    onPause,
    onUnmute,
    onOpenMiniplayer,
  }) {
    const { userConsentValue: healthcareConsent } =
      useUserHealthcareLegalConsent();
    const isLocked = !canUserAccessItem(video);
    const canAccess = !isLocked;
    const isMobile = useMediaQuery(media.w.lessThan.desktop);

    const [showShareOverlay, setShowShareOverlay] = useState(false);
    const [relatedVideosVisible, setRelatedVideosVisible] = useState(false);

    const showMuteOverlay =
      !relatedVideosVisible &&
      !(isMobile && miniplayer) &&
      playing &&
      muted &&
      !unmutedOnce &&
      healthcareConsent &&
      ready &&
      canAccess;

    const showUserActionsOverlay = controls?.userActions?.enabled ?? true;

    const allowMiniplayer =
      (controls?.userActions?.miniplayer ?? true) && !fullscreen;

    const allowShare = controls?.userActions?.share ?? true;

    const showMiniplayerActionsOverlay =
      (controls?.miniplayerActions?.enabled ?? false) &&
      !isMobile &&
      !fullscreen;

    const showTitleOverlay = controls?.title?.enabled && !playing;

    const showContactButtonOverlay = !miniplayer && !isLocked;

    const showRelatedVideosOverlay =
      (controls?.relatedVideos?.enabled ?? true) &&
      video.source?.format === VideoFormat.Wistia &&
      ready &&
      canAccess;

    const showShortcodesOverlay =
      (controls?.shortcodes?.enabled ?? true) &&
      video.source?.format === VideoFormat.Wistia &&
      video.shortcodes != null &&
      ready &&
      canAccess;

    const showVideoSummary =
      !showMuteOverlay &&
      !showShareOverlay &&
      controls?.summary?.enabled &&
      !fullscreen &&
      healthcareConsent &&
      ready &&
      canAccess;

    const showHealthcareConsentOverlay =
      (controls?.healthcareConsent?.enabled ?? true) && canAccess;

    const showMdrDisclaimerOverlay =
      (controls?.mdrDisclaimer?.enabled ?? true) && canAccess;

    useEffect(() => {
      setShowShareOverlay(false);
    }, [video]);

    const openShareOverlay = useCallback(() => {
      setShowShareOverlay(true);
    }, [setShowShareOverlay]);

    const closeShareOverlay = useCallback(() => {
      setShowShareOverlay(false);
    }, [setShowShareOverlay]);

    return (
      <Theme theme={darkTheme}>
        <AnimatePresence>
          {showVideoSummary && (
            <VideoSummaryOverlay key="VideoSummaryOverlay" video={video} />
          )}

          {showMuteOverlay && (
            <UnmuteOverlay key="UnmuteOverlay" onUnmute={onUnmute} />
          )}

          {showUserActionsOverlay && (
            <UserActionsOverlay
              key="UserActionsOverlay"
              video={video}
              allowMiniplayer={allowMiniplayer}
              allowShare={allowShare}
              onShareOpen={openShareOverlay}
              onOpenMiniplayer={onOpenMiniplayer}
            />
          )}

          {showTitleOverlay && (
            <TitleOverlay
              key="TitleOverlay"
              video={video}
              backHref={controls?.title?.backHref}
            />
          )}

          {showContactButtonOverlay && (
            <ContactButtonOverlay key="ContactButtonOverlay" />
          )}

          {showRelatedVideosOverlay && (
            <RelatedVideosOverlay
              key="RelatedVideosOverlay"
              time$={time$}
              video={video}
              duration={duration}
              onVisibilityChange={setRelatedVideosVisible}
            />
          )}

          {showShortcodesOverlay && (
            <ShortcodesOverlay
              key="ShortcodesOverlay"
              time$={time$}
              shortcodes={video.shortcodes}
              onClick={onPause}
            />
          )}

          {showShareOverlay && (
            <ShareOverlay titles close={closeShareOverlay} />
          )}

          {showHealthcareConsentOverlay && (
            <HealthcareLegalConsentOverlay
              key="HealthcareLegalConsentOverlay"
              onPlay={onPlay}
              onUnmute={onUnmute}
              backgroundOnly={controls?.healthcareConsent?.backgroundOnly}
              onBackgroundClick={controls?.healthcareConsent?.onBackgroundClick}
            />
          )}

          {showMdrDisclaimerOverlay && (
            <MdrDisclaimerOverlay
              key="MdrDisclaimerOverlay"
              video={video}
              onUnmute={onUnmute}
              onPlay={onPlay}
              onPause={onPause}
            />
          )}

          {showMiniplayerActionsOverlay && (
            <MiniplayerActionsOverlay
              onReturn={controls?.miniplayerActions?.onReturn ?? (() => null)}
              onClose={controls?.miniplayerActions?.onClose ?? (() => null)}
            />
          )}
        </AnimatePresence>
      </Theme>
    );
  },
);
