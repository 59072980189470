import { memo } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme } from 'src/styles/theme';
import { StyleProps } from '@summer/jst-react';
import { useTranslate } from 'react-t7e';
import { Miniplayer } from 'src/components/icons/Miniplayer';
import { makeTransition } from 'src/utils/motion/transitions';
import { OverlayLayer } from 'src/components/player/common';

const StyledOverlayLayer = styled(OverlayLayer)`
  z-index: 100;
`;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.text.palette.primary};
  padding: 20px;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
`;

const Text = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 20px;
`;

const transition = makeTransition({
  fade: true,
});

// eslint-disable-next-line react/display-name
export const MiniplayerInfoOverlay = memo(({ ...props }: StyleProps) => {
  const { _ } = useTranslate();
  return (
    <StyledOverlayLayer>
      <Theme theme={darkTheme}>
        <Container className="player__pip-overlay" {...props} {...transition}>
          <Miniplayer size={37} />
          <Text>{_('This video is playing in the miniplayer')}</Text>
        </Container>
      </Theme>
    </StyledOverlayLayer>
  );
});
