import React, { memo } from 'react';
import styled from 'styled-components';
import { UserActions } from 'src/modules/materials/components/UserActions';
import { media } from 'src/styles/designs';
import { Videos } from 'src/graphql/schema.graphql';
import { canUserAccessItem } from 'src/modules/user/utils/permissions';
import { VideoFormat } from 'src/modules/videos/models';

const StyledUserActions = styled(UserActions)`
  margin: 0;
  top: 16px;
  right: 16px;

  ${media.w.greaterThanOrEqual.desktop} {
    top: 22px;
    right: 40px;
    gap: 16px;
  }
`;

export const UserActionsOverlay = memo<{
  video: Videos;
  allowMiniplayer?: boolean;
  allowShare?: boolean;
  onShareOpen: () => void;
  onOpenMiniplayer: () => void;
}>(function UserActionsOverlay({
  video,
  allowMiniplayer = true,
  allowShare = true,
  onShareOpen,
  onOpenMiniplayer,
}) {
  const canFavourite = video.source?.format === VideoFormat.Wistia;
  const canMiniplayer = allowMiniplayer && canUserAccessItem(video);
  const canShare = !!(
    allowShare &&
    (!canUserAccessItem(video) || video.source?.format === VideoFormat.Wistia)
  );

  return (
    <StyledUserActions
      item={video}
      onShareOpen={canShare ? onShareOpen : undefined}
      onOpenMiniplayer={canMiniplayer ? onOpenMiniplayer : undefined}
      favouriteButton={canFavourite}
    />
  );
});
