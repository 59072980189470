import { IconBox, IconProps } from '@summer/jst-react-icon';

export const Talks = (props: Omit<IconProps, 'ref'>) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.82301 3.82398C3.95571 3.69128 4.1357 3.61672 4.32337 3.61672H44.5791C44.7668 3.61672 44.9467 3.69128 45.0795 3.82398C45.2122 3.95668 45.2867 4.13667 45.2867 4.32435V16.8704C45.2865 16.8816 45.2864 16.8927 45.2864 16.904C45.2864 16.9152 45.2865 16.9263 45.2867 16.9375V32.0002C45.2867 32.1878 45.2122 32.3678 45.0795 32.5005C44.9468 32.6332 44.7668 32.7078 44.5791 32.7078H16.9367C16.9255 32.7076 16.9143 32.7075 16.903 32.7075C16.8917 32.7075 16.8804 32.7076 16.8692 32.7078H13.6166L3.61575 40.7927V4.32435C3.61575 4.13667 3.6903 3.95669 3.82301 3.82398ZM15.0946 36.3245H14.8957L-0.000976562 48.3674V4.32435C-0.000976562 3.17746 0.454624 2.07754 1.2656 1.26657C2.07657 0.455601 3.17648 0 4.32337 0H44.5791C45.726 0 46.8259 0.455599 47.6369 1.26657C48.4478 2.07755 48.9034 3.17746 48.9034 4.32435V15.0956H59.6747C60.8216 15.0956 61.9215 15.5512 62.7324 16.3622C63.5434 17.1731 63.999 18.273 63.999 19.4199V63.463L49.1024 51.4201H19.419C18.2721 51.4201 17.1722 50.9645 16.3612 50.1535C15.5502 49.3425 15.0946 48.2426 15.0946 47.0957V36.3245ZM18.7113 36.3245V47.0957C18.7113 47.2834 18.7859 47.4634 18.9186 47.5961C19.0513 47.7288 19.2313 47.8034 19.419 47.8034H50.3815L60.3823 55.8883V19.4199C60.3823 19.2323 60.3077 19.0523 60.175 18.9196C60.0423 18.7869 59.8623 18.7123 59.6747 18.7123H48.9034V32.0002C48.9034 33.147 48.4478 34.247 47.6369 35.0579C46.8259 35.8689 45.726 36.3245 44.5791 36.3245H18.7113Z"
    />
  </IconBox>
);
