import styled, { css } from 'styled-components';
import { memo } from 'react';
import { fromTheme } from 'src/styles/theme';
import { useTranslate } from 'react-t7e';
import { media } from 'src/styles/designs';
import {
  OverlayLayerCentered,
  playerFadeZIndex,
} from 'src/components/player/common';
import { transparentize } from 'polished';
import BigCircledUnmute from 'src/components/player/overlays/BigCircledUnmute';
import { motion } from 'framer-motion';
import { makeTransition } from 'src/utils/motion/transitions';

const Container = styled(OverlayLayerCentered).attrs({
  'data-no-focus-mode': false,
})<{
  $iconOnly: boolean;
}>`
  ${media.w.lessThan.desktop} {
    ${({ $iconOnly }) =>
      $iconOnly &&
      css`
        svg {
          width: 50px;
          height: 50px;
        }
      `}
  }
`;

const Unmute = styled(motion.div)`
  width: 100%;
  height: 100%;

  z-index: ${playerFadeZIndex - 2};
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  transition: color ease-out 0.2s, transform ease-out 0.2s;
  cursor: pointer;
  padding: 20px 40px;
  font-weight: 600;
  transform: scale(1);

  color: ${fromTheme((t) => transparentize(0.2, t.text.palette.heavy))};

  &:hover {
    color: ${fromTheme((t) => t.text.palette.heavy)};
    transform: scale(1.05);
  }

  font-size: 21px;
  line-height: 110%;

  text-align: center;
  letter-spacing: 0.02em;

  ${media.w.lessThan.desktop} {
    flex-direction: column;
    font-size: 14px;
  }
`;

const StyledBigUnmute = styled(BigCircledUnmute)`
  width: 131px;
  height: 131px;

  ${media.w.lessThan.desktop} {
    width: 70px;
    height: 70px;
  }
`;

export interface MuteOverlayProps {
  onUnmute: () => void;
  iconOnly?: boolean;
}

export const UnmuteOverlay = memo(function UnmuteOverlay({
  onUnmute,
  iconOnly,
}: MuteOverlayProps) {
  const { _ } = useTranslate();

  return (
    <Container
      className="player__mute-overlay"
      onClick={onUnmute}
      $iconOnly={!!iconOnly}
    >
      <Unmute {...makeTransition({ fade: true, speed: 'faster' })}>
        <StyledBigUnmute />
        {!iconOnly && <div>{_('Click to unmute')}</div>}
      </Unmute>
    </Container>
  );
});
