import React, { SVGAttributes } from 'react';
import { MotionProps } from 'framer-motion';

const BigCircledUnmute = (
  props: SVGAttributes<SVGSVGElement> & MotionProps,
) => (
  <svg
    width="131"
    height="131"
    viewBox="0 0 131 131"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="65.5" cy="65.5" r="65" stroke="currentColor" strokeWidth="1" />
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2638 34V97L52.0898 78.9758H34.9844V52.0242H52.0898L75.2638 34ZM70.525 43.689L53.7157 56.7629H39.7231V74.2371H53.7157L70.525 87.311V43.689Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.3618 56.4658V74.5323H81.623V56.4658H86.3618Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.2466 52.0234V78.9751H90.5078V52.0234H95.2466Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default BigCircledUnmute;
