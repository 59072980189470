import { localStorage } from 'src/utils/storage';

export enum PlayerSettings {
  Volume = 'neauvia-player-volume',
}

export const getSavedPlayerSettings = <T>(key: PlayerSettings): T | null => {
  return localStorage.getItem(key) ?? null;
};

export const savePlayerSettings = <T>(key: PlayerSettings, value: T): boolean =>
  localStorage.setItem(key, value);
