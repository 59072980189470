import styled from 'styled-components';
import { media } from 'src/styles/designs';
import { Videos_Shortcodes_Translations_Products } from 'src/graphql/schema.graphql';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { PimImage } from 'src/components/common/PimImage';
import { lineClamp } from 'src/styles/common';
import { TrackedLink } from 'src/components/TrackedLink';
import { route } from 'src/services/route';
import { useProductDetailsQuery } from 'src/modules/products/hooks/useProductDetailsQuery';

const ShortcodeProductMultiWrapper = styled.div`
  display: flex;
`;

const ShordcodeProduct = styled.a`
  position: relative;
  display: grid;
  grid-template-areas: 'image content';
  padding: 8px 10px 8px;
  grid-gap: 8px;
  flex: 0 0 auto;

  ${media.w.greaterThanOrEqual.desktop} {
    grid-template-areas: 'image' 'content';
    padding: 16px 4px 21px 11px;
  }
  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 2px;
      width: 1px;
      height: 50%;
      opacity: 0.2;
      transform: translateY(-50%);
      background: ${({ theme }) => theme.text.palette.primary};
    }
  }
`;

const ShortcodelDescription = styled.p`
  grid-area: content;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.text.palette.primary};
  width: 95px;
  white-space: initial !important;
  ${lineClamp(4)};

  ${media.w.lessThan.desktop} {
    font-size: 10px;
    line-height: 12px;
    width: 70px;
    margin: auto;
  }
`;

const ShortcodeImage = styled.div`
  position: relative;
  grid-area: image;
  width: 16px;
  height: 44px;
  ${media.w.greaterThanOrEqual.desktop} {
    width: auto;
    height: 70px;
    margin: 0 10px;
  }
`;

export interface ShortcodeSingleProductProps {
  productPimCode: string;
}

const ShortcodeMultiProductItem = ({
  productPimCode,
}: ShortcodeSingleProductProps) => {
  const translateFn = useTranslateItem();

  const { data: product } = useProductDetailsQuery(productPimCode);

  if (!product || !product.image) {
    return null;
  }

  return (
    <TrackedLink href={route.products().page(product)} passHref>
      <ShordcodeProduct>
        <ShortcodeImage>
          <PimImage src={product.image.id} layout="fill" objectFit="contain" />
        </ShortcodeImage>
        <ShortcodelDescription>
          {translateFn(product).description_short}
        </ShortcodelDescription>
      </ShordcodeProduct>
    </TrackedLink>
  );
};

export interface ShortcodeMultiProductProps {
  products: Videos_Shortcodes_Translations_Products[];
}

export const ShortcodeMultiProduct: React.FC<ShortcodeMultiProductProps> = ({
  products,
}) => {
  const children = products.map((product) => (
    <ShortcodeMultiProductItem
      key={product.id}
      productPimCode={product.products_id.pim_code}
    />
  ));

  if (children.every((c) => c === null)) {
    return null;
  }

  return (
    <ShortcodeProductMultiWrapper>{children}</ShortcodeProductMultiWrapper>
  );
};
