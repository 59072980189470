import * as React from 'react';
import { withErrorBoundary } from 'react-error-boundary';

/**
 * Ignore component if it throws an error during rendering.
 * @constructor
 */
const RenderNothing = () => null;
export const withSilentErrorBoundary = <P,>(
  Component: React.ComponentType<P>,
): React.ComponentType<P> => {
  return withErrorBoundary<P>(Component, {
    FallbackComponent: RenderNothing,
    onError(e: unknown) {
      console.error('[withSilentErrorBoundary]', e);
    },
  });
};
