import styled from 'styled-components';
import { memo } from 'react';
import {
  OverlayLayer,
  playerOverlayAboveControlsZIndex,
} from 'src/components/player/common';
import {
  ShareOverlay as CommonShareOverlay,
  ShareOverlayProps,
} from 'src/components/common/ShareOverlay';

const Container = styled(OverlayLayer)`
  z-index: ${playerOverlayAboveControlsZIndex};
`;

export const ShareOverlay = memo(function ShareOverlay({
  ...props
}: ShareOverlayProps) {
  return (
    <Container>
      <CommonShareOverlay {...props} />
    </Container>
  );
});
