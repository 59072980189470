import React, { FC } from 'react';
import styled from 'styled-components';
import { StyleProps } from '@summer/jst-react';
import { ellipsis, wordWrap } from 'polished';
import { SpeakerCard } from 'src/modules/materials/components/SpeakerCard';
import { lineClamp } from 'src/styles/common';
import { Button } from 'src/components/common/Button';
import { ChevronBottom } from 'src/components/icons/ChevronBottom';
import { media } from 'src/styles/designs';
import { TrackedLink } from 'src/components/TrackedLink';
import { Videos } from 'src/graphql/schema.graphql';
import { useTranslate } from 'react-t7e';
import { route } from 'src/services/route';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  row-gap: 12px;
  color: ${(props) => props.theme.text.palette.primary};
`;

const Header = styled.header`
  min-height: 0;

  ${media.w.lessThan.desktop} {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
`;

const HeaderContent = styled.h2`
  ${lineClamp(3)};
  max-height: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 26px;
  }
  ${media.w.lessThan.desktop} {
    margin-top: 2px;
  }
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  min-height: 0;

  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

const DescriptionContent = styled.div`
  ${lineClamp(4)};
  max-height: 100%;
`;

const Tags = styled.div`
  display: grid;
  grid-template-columns: repeat(2, fit-content(75%));
  grid-column-gap: 8px;
  grid-template-rows: auto;
  min-height: 0;
`;

const Tag = styled.span`
  color: ${(props) => props.theme.text.palette.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  min-width: 0;
  ${ellipsis('100%')};
  ${wordWrap('nowrap')};
`;

const Speakers = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  grid-row-gap: 6px;
  min-height: 0;
`;

const Footer = styled.footer`
  width: 100%;
  margin-top: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  align-self: flex-end;

  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

const MobileMoreButton = styled(Button)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 10px;

  ${media.w.greaterThanOrEqual.desktop} {
    display: none;
  }
`;

export interface VideoDescriptionProps extends StyleProps {
  video: Videos;
  moreButton?: boolean;
}

export const VideoSummary: FC<VideoDescriptionProps> = ({
  video,
  moreButton = true,
  ...props
}) => {
  const { _ } = useTranslate();
  const translateItem = useTranslateItem();

  const { name, description, tags, speakers } = translateItem(video);

  return (
    <Container {...props}>
      <Header>
        <HeaderContent>{name}</HeaderContent>
        {moreButton && (
          <TrackedLink href={route.video(video).page()} passHref>
            <MobileMoreButton
              forwardedAs="a"
              kind="text"
              variant="secondary"
              icon={ChevronBottom}
            />
          </TrackedLink>
        )}
      </Header>
      <Description>
        <DescriptionContent>{description}</DescriptionContent>
      </Description>
      <Tags>
        {tags
          .slice(0, 4)
          .map((a) => a.tags_id)
          .map(translateItem)
          .map((a) => (
            <Tag key={a.id}>{a.name}</Tag>
          ))}
      </Tags>
      <Speakers>
        {speakers
          .map((a) => a.people_id)
          .slice(0, 2)
          .map((a) => (
            <SpeakerCard key={a.id} speaker={a} />
          ))}
      </Speakers>
      {moreButton && (
        <Footer>
          <TrackedLink href={route.video(video).page()} passHref>
            <Button as="a" kind="text" variant="secondary" icon={ChevronBottom}>
              {_('More')}
            </Button>
          </TrackedLink>
        </Footer>
      )}
    </Container>
  );
};
