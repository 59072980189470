import styled, { css } from 'styled-components';
import { TranslatedItem } from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { Theme } from 'vendor/mods/styled-components';
import { redTheme } from 'src/styles/theme';
import { makeTransition } from 'src/utils/motion/transitions';
import React from 'react';
import { motion } from 'framer-motion';
import { shortcodeCommonLayout } from 'src/components/player/overlays/ShortcodesOverlay/common';
import { transparentize } from 'polished';
import { media } from 'src/styles/designs';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { FormatHour } from 'src/components/common/FormatDate';

const ShortcodeLabelContainer = motion(styled.div<{
  $location: string | 'left' | 'right';
}>`
  && {
    ${shortcodeCommonLayout};
    display: grid;
    grid-auto-flow: row;
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0;
    color: ${({ theme }) => theme.text.palette.primary};

    ${({ $location }) =>
      $location === 'left'
        ? css`
            padding: 14px 70px 14px 25px;
            text-align: left;
            background: linear-gradient(
              to right,
              ${({ theme }) => theme.palette.accent} 25.11%,
              ${({ theme }) => transparentize(1, theme.palette.accent)} 97.65%
            );
            -webkit-mask-image: linear-gradient(
              to right,
              rgba(0, 0, 0, 1) calc(100% - 70px),
              rgba(0, 0, 0, 0) 100%
            );

            ${media.w.lessThan.desktop} {
              padding: 9px;
              padding-right: 45px;
              -webkit-mask-image: linear-gradient(
                to left,
                rgba(0, 0, 0, 1) calc(100% - 45px),
                rgba(0, 0, 0, 0) 100%
              );
            }
          `
        : css`
            padding: 14px 25px 14px 70px;
            text-align: right;
            background: linear-gradient(
              to left,
              ${({ theme }) => theme.palette.accent} 25.11%,
              ${({ theme }) => transparentize(1, theme.palette.accent)} 97.65%
            );
            -webkit-mask-image: linear-gradient(
              to left,
              rgba(0, 0, 0, 1) calc(100% - 70px),
              rgba(0, 0, 0, 0) 100%
            );

            ${media.w.lessThan.desktop} {
              padding: 9px;
              padding-left: 45px;
              -webkit-mask-image: linear-gradient(
                to left,
                rgba(0, 0, 0, 1) calc(100% - 45px),
                rgba(0, 0, 0, 0) 100%
              );
            }
          `}

    backdrop-filter: blur(12px);
  }
`);

const ShortcodeLabelTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  ${media.w.lessThan.desktop} {
    font-size: 10px;
    line-height: 12px;
  }
`;
const ShortcodeLabelDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  ${media.w.lessThan.desktop} {
    font-size: 10px;
    line-height: 12px;
  }
  time {
    margin-right: 4px;
    font-weight: 400;
  }
`;

export function ShortcodeLabel(props: {
  shortcode: TranslatedItem<Videos_Shortcodes>;
}) {
  const { label_title, label_description, location } = props.shortcode;
  return (
    <Theme theme={redTheme}>
      <ShortcodeLabelContainer
        layout
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...makeTransition({
          fade: true,
          from: location === 'left' ? 'left' : 'right',
          speed: 'slow',
        })}
        $location={location}
        kind="text"
        {...props}
      >
        {label_title ? (
          <ShortcodeLabelTitle className="shortcodelabel__title">
            <Markdown>{label_title}</Markdown>
          </ShortcodeLabelTitle>
        ) : null}
        {label_description ? (
          <ShortcodeLabelDescription className="shortcodelabel__description">
            <Markdown
              Hour={({ children }) =>
                Array.isArray(children) ? (
                  <FormatHour>{children[0]}</FormatHour>
                ) : null
              }
            >
              {label_description}
            </Markdown>
          </ShortcodeLabelDescription>
        ) : null}
      </ShortcodeLabelContainer>
    </Theme>
  );
}
