export enum PlayerId {
  Common = 'common',
  TVLike = 'tvLike',
}

export const playerIdFactory: (
  playerId: PlayerId,
  objectId?: string,
) => string = (playerId: PlayerId, objectId?: string) => {
  if (playerId === PlayerId.TVLike) {
    return PlayerId.TVLike;
  }

  return `${playerId ?? PlayerId.Common}__${objectId}`;
};
