import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Videos } from 'src/graphql/schema.graphql';
import { Observable } from '@hitorisensei/kefir-atomic';
import { AnimatePresence, motion } from 'framer-motion';
import { playerFadeZIndex, OverlayLayer } from 'src/components/player/common';
import { useStream } from 'vendor/hooks/useStream';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbnail } from 'src/modules/materials/components/Thumbnail';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme } from 'src/styles/theme';
import { makeTransition } from 'src/utils/motion/transitions';
import { media } from 'src/styles/designs';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { layer, lineClamp } from 'src/styles/common';
import { useTranslate } from 'react-t7e';
import { transparentize } from 'polished';
import { Button } from 'src/components/common/Button';
import { Close } from 'src/components/icons/Close';
import { useRelatedItemsListQuery } from 'src/modules/materials/hooks/useRelatedItemsListQuery';

const mobileZIndex = playerFadeZIndex + 1;
const desktopZIndex = playerFadeZIndex - 1;
const maxTimeOffset = 15;

const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding: 0 36px 60px;
  pointer-events: none;
  ${media.w.lessThan.desktop} {
    padding: 0 10px;
    align-items: center;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

const VideoSwiper = styled(Swiper)`
  .swiper-slide {
    width: 264px;
    margin: 0 10px;
    opacity: 1 !important;
    pointer-events: all;
    .nc-thumbnail__title {
      ${lineClamp(2)}
    }
    ${media.w.lessThan.desktop} {
      width: 110px;
      margin: 0 2px;
      .nc-thumbnail__title {
        ${lineClamp(1)}
      }
      .nc-thumbnail__icon {
        --base-unit: 100vw / 620;
      }
    }
  }
`;

const InfoBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2px 10px;
  ${media.w.greaterThanOrEqual.desktop} {
    margin: 0 10px 14px;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.text.palette.primary};
  font-weight: 500;
  font-size: 12px;

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 16px;
  }
`;

const Fade = styled(motion.div)`
  ${layer};
  background: ${({ theme }) => transparentize(0.15, theme.palette.background)};
  pointer-events: all;
  ${media.w.greaterThanOrEqual.desktop} {
    pointer-events: none;
    height: 460px;
    top: auto;
    bottom: -60px;
    transform: rotate(-180deg);
    background: linear-gradient(
      186.88deg,
      rgba(0, 0, 0, 0.4) 2.61%,
      rgba(90, 90, 90, 0) 66.25%
    );
  }
`;

const CloseButton = styled(Button)`
  && {
    margin-left: 10px;
    width: 32px;
    height: 32px;
    pointer-events: all;
    ${media.w.lessThan.desktop} {
      width: 24px;
      height: 24px;
      .btn__content {
        padding: 5px;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export interface RelatedVideosOverlayProps
  extends HTMLAttributes<HTMLDivElement> {
  time$: Observable<number, unknown>;
  video: Videos;
  duration?: number;
  onVisibilityChange?: (v: boolean) => void;
}

export const RelatedVideosOverlay: FC<RelatedVideosOverlayProps> = ({
  time$,
  video,
  duration,
  onVisibilityChange,
  ...props
}) => {
  const [hasBeenClosed, setHasBeenClosed] = useState(false);
  const isDesktop = useMediaQuery(media.w.greaterThanOrEqual.desktop);

  const { _ } = useTranslate();

  const timeOffset = useMemo(() => {
    if (!duration) return 0;

    const offset = Math.round(duration * 0.2);

    return offset > maxTimeOffset ? maxTimeOffset : offset;
  }, [duration]);

  const isInCorrectTimeRange = useStream(
    () => time$.map((time) => !!(duration && time >= duration - timeOffset)),
    false,
    [time$, duration],
  );

  const { data: relatedVideos } = useRelatedItemsListQuery(video, {
    enabled: !hasBeenClosed && isInCorrectTimeRange,
  });

  const handleClose = useCallback(() => setHasBeenClosed(true), []);

  useEffect(() => {
    onVisibilityChange?.(isInCorrectTimeRange);
  }, [isInCorrectTimeRange, onVisibilityChange]);

  return (
    <OverlayLayer
      {...props}
      style={{
        zIndex: isDesktop ? desktopZIndex : mobileZIndex,
      }}
    >
      <Theme theme={darkTheme}>
        <AnimatePresence exitBeforeEnter>
          {!!(
            !hasBeenClosed &&
            relatedVideos &&
            relatedVideos.length > 0 &&
            isInCorrectTimeRange
          ) && (
            <Wrapper
              {...makeTransition({
                from: 'bottom',
                to: 'bottom',
                fade: true,
              })}
            >
              <Fade
                {...makeTransition({
                  fade: true,
                })}
              />
              <Content>
                <InfoBar>
                  <Text>{_('SEE ALSO')}</Text>
                  <CloseButton
                    compound
                    kind="circled"
                    icon={Close}
                    iconProps={{ size: 14 }}
                    onClick={handleClose}
                  />
                </InfoBar>
                <VideoSwiper
                  threshold={15}
                  slidesPerView="auto"
                  watchSlidesProgress
                  wrapperTag="ul"
                >
                  {relatedVideos.slice(0, isDesktop ? 4 : 3).map((video) => {
                    return (
                      <SwiperSlide key={video.id} tag="li">
                        <Thumbnail
                          key={video.id}
                          item={video}
                          showUserActions={isDesktop}
                          showTags={false}
                        />
                      </SwiperSlide>
                    );
                  })}
                </VideoSwiper>
              </Content>
            </Wrapper>
          )}
        </AnimatePresence>
      </Theme>
    </OverlayLayer>
  );
};
