import styled from 'styled-components';
import { TranslatedItem } from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { ShortcodeLabel } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeLabel';
import { lineClamp } from 'src/styles/common';
import { media } from 'src/styles/designs';
import { useTranslate } from 'react-t7e';

const StyledShortcodeLabel = styled(ShortcodeLabel)`
  padding: 6px 15px 11px 60px !important;
  && {
    .shortcodelabel__title {
      text-align: left;
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      text-transform: uppercase;
    }
    .shortcodelabel__description {
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      font-weight: 300;
      max-width: 100%;
      ${lineClamp(1)};
    }
  }
  ${media.w.greaterThanOrEqual.desktop} {
    padding: 6px 15px 11px 70px !important;
    max-width: 60%;
  }
`;

export function ShortcodeUpcomingVideo(props: {
  shortcode: TranslatedItem<Videos_Shortcodes>;
}) {
  const { _ } = useTranslate();
  return (
    <StyledShortcodeLabel
      {...props}
      shortcode={{ ...props.shortcode, label_title: _('Next') }}
    />
  );
}
