import styled from 'styled-components';
import { media } from 'src/styles/designs';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';
import { PimImage } from 'src/components/common/PimImage';
import { Button, CleanButton } from 'src/components/common/Button';
import { useTranslate } from 'react-t7e';
import { ChevronRight } from 'src/components/icons/ChevronRight';
import { lineClamp } from 'src/styles/common';
import { useProductDetailsQuery } from 'src/modules/products/hooks/useProductDetailsQuery';
import { TrackedLink } from 'src/components/TrackedLink';
import { route } from 'src/services/route';

const ShortcodeProductSingleWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: 'header button' 'content button';
  grid-gap: 1px;
  padding: 7px 6px 5px 14px;
  ${media.w.greaterThanOrEqual.desktop} {
    grid-gap: 10px;
    grid-template-areas: '. header' '. content' '. button';
    padding: 20px 32px 23px 80px;
  }
`;

const ShortcodelDescription = styled.p`
  grid-area: content;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.text.palette.primary};
  max-width: 165px;
  white-space: initial !important;
  ${lineClamp(6)};

  ${media.w.lessThan.desktop} {
    font-size: 10px;
    line-height: 13px;
    ${lineClamp(3)};
  }
`;

const ShortcodeHeading = styled(ShortcodelDescription).attrs({
  as: 'h3',
})`
  grid-area: header;
  font-weight: 500;
  text-transform: uppercase;
`;

const ShortcodeButton = styled(Button).attrs({ forwardedAs: 'a' })`
  && {
    grid-area: button;
    background: ${({ theme }) => theme.palette.accent};
    border-color: ${({ theme }) => theme.palette.accent};
    height: 42px;
    min-width: 165px;
    max-width: 165px;
    margin-top: 10px;
    &:hover {
      background: transparent !important;
    }
    ${media.w.lessThan.desktop} {
      display: none;
    }
  }
`;

const ShortcodeImage = styled.div`
  position: absolute;
  top: 30%;
  transform: translate(-50%, -30%);
  left: 0;
  width: 24px;
  height: 63px;
  ${media.w.greaterThanOrEqual.desktop} {
    top: 0;
    left: 42px;
    transform: translate(-50%, 0);
    width: 81px;
    height: 209px;
    max-height: 110%;
  }
  & > div {
    overflow: visible;
  }
`;

const ArrowButton = styled(CleanButton)`
  grid-area: button;
  margin-left: 5px;
  padding: 5px 10px;
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.text.palette.primary};
  ${media.w.greaterThanOrEqual.desktop} {
    display: none;
  }
`;

export interface ShortcodeSingleProductProps {
  productPimCode: string;
}

export const ShortcodeSingleProduct: React.FC<ShortcodeSingleProductProps> = ({
  productPimCode,
}) => {
  const { _ } = useTranslate();
  const translateFn = useTranslateItem();

  const { data: product } = useProductDetailsQuery(productPimCode);

  if (!product || !product.image) {
    return null;
  }

  return (
    <ShortcodeProductSingleWrapper key={product.code}>
      <ShortcodeImage>
        {product.image && (
          <PimImage src={product.image.id} layout="fill" objectFit="contain" />
        )}
      </ShortcodeImage>

      <ShortcodeHeading>{translateFn(product).name}</ShortcodeHeading>
      <ShortcodelDescription>
        {translateFn(product).description_short}
      </ShortcodelDescription>

      <TrackedLink href={route.products().page(product)} passHref>
        <ShortcodeButton>{_('featured product', 'shortcodes')}</ShortcodeButton>
      </TrackedLink>

      <TrackedLink href={route.products().page(product)} passHref>
        <ArrowButton as="a">
          <ChevronRight size={16} />
        </ArrowButton>
      </TrackedLink>
    </ShortcodeProductSingleWrapper>
  );
};
