import { createSelector } from '@reduxjs/toolkit';

export const selectMiniplayerState = (store: StoreRoot) => store.miniplayer;

export const selectMiniplayerCurrent = createSelector(
  selectMiniplayerState,
  (state) => state.current,
);

export const selectMiniplayerOnReturn = createSelector(
  selectMiniplayerState,
  (state) => state.onReturn,
);
