import React, {
  ComponentType,
  createRef,
  forwardRef,
  memo,
  PureComponent,
  Ref,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { ar, layer } from 'src/styles/common';
import {
  OverlayController,
  OverlayControls,
} from 'src/components/player/OverlayController';
import { Videos } from 'src/graphql/schema.graphql';
import {
  constant,
  fromPoll,
  never,
  Observable,
} from '@hitorisensei/kefir-atomic';
import { parse, toSeconds } from 'iso8601-duration';
import {
  PlayerStrategyAPI,
  PlayerStrategyProps,
} from 'src/components/player/playerStrategy/types';
import { playerStrategyProvider } from 'src/components/player/playerStrategy/playerStrategyProvider';
import { StyleProps } from '@summer/jst-react';
import { PlayerId, playerIdFactory } from 'src/components/player/playerId';
import memoizee from 'memoizee';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { AppAction } from 'src/store/actions';
import { selectMiniplayerCurrent } from 'src/store/miniplayer/selectors';
import { MiniplayerInfoOverlay } from 'src/components/player/overlays/MiniplayerInfoOverlay';
import noop from 'lodash/noop';
import { selectActivePlayerId } from 'src/store/app/selectors';
import {
  PlayerSettings,
  getSavedPlayerSettings,
  savePlayerSettings,
} from 'src/components/player/playerSettings';
import { useUID } from 'react-uid';

const iso8601ToSeconds = memoizee((a: string) => toSeconds(parse(a)));

const Container = styled.div<{ $ar?: number }>`
  direction: ltr !important;

  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  vertical-align: top;
  position: relative;
  z-index: 3;

  ${({ $ar }) =>
    $ar != null
      ? ar($ar)
      : css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}

  &:hover .np-overlay__video-summary {
    transform: translateX(0);
    opacity: 1;
  }

  &:hover .player__miniplayer-actions-overlay {
    opacity: 1;
  }
`;

const ScrollHandle = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  top: calc(-1 * var(--header-static-height));
`;

export interface NeauviaPlayerStateSnapshot {
  ready: boolean;
  fullscreen: boolean;
  playing: boolean;
  playedOnce: boolean;
  volume: number;
  muted: boolean;
  unmutedOnce: boolean;
  time: number;
  duration: number;
}

export interface NeauviaPlayerAPI {
  stateSnapshot: NeauviaPlayerStateSnapshot;
  play: () => void;
  seekTo: (to: number) => void;
  mute: () => void;
  unmute: () => void;
  openInMiniplayer: (state?: Partial<AppActions['openMiniplayer']>) => void;
}

export interface NeauviaPlayerControls extends OverlayControls {
  native?: {
    enabled: boolean;
  };
}

interface NeauviaPlayerComponentProps extends StyleProps {
  video: Videos;
  autoPlay?: boolean;
  playFrom?: number;
  loop?: boolean;
  initialMuted?: boolean;

  ar?: number;
  controls?: NeauviaPlayerControls;
  playerId: string;

  wistiaOptions?: WistiaPlayer.PlayerOptions;

  onReady?: (initialState: NeauviaPlayerStateSnapshot) => void;
  onPlay?: () => void;
  onSeek?: (time: number) => void;
  onPause?: () => void;
  onEnded?: () => void;
  onMute?: () => void;
  onUnmute?: () => void;
  onVolumeChange?: (v: number) => void;
  onEnterFullscreen?: () => void;
  onLeaveFullscreen?: () => void;

  isActivePlayer: boolean;
  setAsActivePlayer: () => void;

  miniplayer: boolean;
  isInMiniplayer: boolean;
  openMiniplayer: (state: AppActions['openMiniplayer']) => void;
  attachMiniplayer: (onReturn: AppActions['attachMiniplayer']) => void;
  detachMiniplayer: () => void;
  resetMiniplayer: () => void;
  miniplayerInitProps?: {
    playing?: boolean;
    playFrom?: number;
    muted?: boolean;
    volume?: number;
  };
}

export interface NeauviaPlayerProps
  extends Omit<
    NeauviaPlayerComponentProps,
    | 'playerId'
    | 'miniplayer'
    | 'isInMiniplayer'
    | 'openMiniplayer'
    | 'attachMiniplayer'
    | 'detachMiniplayer'
    | 'resetMiniplayer'
    | 'miniplayerInitProps'
    | 'isActivePlayer'
    | 'setAsActivePlayer'
  > {
  playerId?: PlayerId;
  onOpenMiniplayer?: () => void;
}

interface ComponentState {
  ready: boolean;
  fullscreen: boolean;
  playing: boolean;
  playedOnce: boolean;
  muted: boolean;
  unmutedOnce: boolean;
  duration: number;
  volume: number;
  disabled: boolean;

  playFrom?: number;
  PlayerComponent?: ComponentType<PlayerStrategyProps>;

  hasError?: boolean;
}

class NeauviaPlayerComponent
  extends PureComponent<NeauviaPlayerComponentProps, ComponentState>
  implements NeauviaPlayerAPI
{
  get stateSnapshot() {
    return {
      ready: this.state.ready,
      fullscreen: this.state.fullscreen,
      playing: this.state.playing,
      playedOnce: this.state.playedOnce,
      volume: this.state.volume,
      muted: this.state.muted,
      unmutedOnce: this.state.unmutedOnce,
      duration: this.state.duration,
      time: this.playerApi?.getCurrentTime() ?? 0,
    };
  }

  openInMiniplayer = (state?: Partial<AppActions['openMiniplayer']>) => {
    if (this.props.miniplayer || this.props.isInMiniplayer) {
      return;
    }

    this.props.openMiniplayer({
      playerId: this.props.playerId,
      video: this.props.video,
      playing: this.state.playing,
      playFrom: this.playerApi?.getCurrentTime(),
      muted: this.state.muted,
      volume: this.state.volume,
      ...state,
    });

    this.props.attachMiniplayer(this.onReturnFromMiniplayer);
  };

  play() {
    this.onPlay();
  }

  seekTo(to: number) {
    this.setState({ playFrom: to });
  }

  mute() {
    this.onMute();
  }

  unmute() {
    this.onUnmute();
  }

  constructor(props: NeauviaPlayerComponentProps) {
    super(props);

    const savedVolume = getSavedPlayerSettings?.<number>(PlayerSettings.Volume);

    const state: ComponentState = {
      ready: false,
      fullscreen: false,
      playing: false,
      playedOnce: false,
      muted: NeauviaPlayerComponent.recentMutedOption ?? false,
      unmutedOnce: !(NeauviaPlayerComponent.recentMutedOption ?? false),
      duration: 0,
      volume: NeauviaPlayerComponent.recentVolumeOption ?? savedVolume ?? 1,
      playFrom: props.playFrom,
      disabled: false,
    };

    if (props.autoPlay && props.video.source != null) {
      state.playing = true;
      state.muted = NeauviaPlayerComponent.recentMutedOption ?? true;
    }

    if (props.initialMuted) {
      state.muted = true;
    }

    // If there was stored miniplayer snapshot, initialize player using that snapshot's state.
    if (this.props.miniplayerInitProps) {
      state.playing = this.props.miniplayerInitProps.playing ?? state.playing;
      state.playFrom =
        this.props.miniplayerInitProps.playFrom ?? state.playFrom;
      state.muted = this.props.miniplayerInitProps.muted ?? state.muted;
      state.volume = this.props.miniplayerInitProps.volume ?? state.volume;
    }

    state.playedOnce = state.playing;
    state.unmutedOnce = !state.muted;

    this.state = state;

    if (this.props.isInMiniplayer) {
      this.props.attachMiniplayer(this.onReturnFromMiniplayer);
    }
  }

  static getDerivedStateFromProps(
    props: NeauviaPlayerComponentProps,
    state: ComponentState,
  ) {
    const stateUpdate: Partial<ComponentState> = {};

    stateUpdate.duration =
      props.video.source?.duration != null
        ? iso8601ToSeconds(props.video.source.duration)
        : 0;

    const PlayerComponent = playerStrategyProvider(props.video.source);
    if (state.PlayerComponent !== PlayerComponent) {
      stateUpdate.PlayerComponent = PlayerComponent;
      /*
        If player changes, we cannot automatically turn on fullscreen, because enabling fullscreen
        requires a trusted event (like a mouse click). So we must deliberately disable it.
       */
      stateUpdate.fullscreen = false;
    }

    stateUpdate.disabled = props.isInMiniplayer;
    if (stateUpdate.disabled) {
      stateUpdate.playing = false;
    }

    return stateUpdate;
  }

  componentDidMount() {
    if (this.props.isInMiniplayer) {
      this.props.attachMiniplayer(this.onReturnFromMiniplayer);
    }
  }

  componentDidUpdate(prevProps: Readonly<NeauviaPlayerComponentProps>) {
    /*
      The code below covers an example use case where user was not logged in (hence absence of source)
      and has logged in (source was downloaded). If the player was set to autoPlay, then it should
      be auto played when the source becomes available.
     */
    if (
      prevProps.video.source == null &&
      this.props.video.source != null &&
      this.props.autoPlay &&
      !this.state.playing
    ) {
      this.onPlay();
    }

    /*
      The code below covers a use case that only one player should be playing at the same time.
      There is an exception to TV player, which cannot be paused, so it should be muted instead.
     */
    if (
      prevProps.isActivePlayer &&
      !this.props.isActivePlayer &&
      !this.state.muted &&
      this.state.playing &&
      !this.props.miniplayer
    ) {
      if (this.props.playerId.includes(PlayerId.TVLike)) {
        this.onMute(true);
      } else {
        this.onPause();
      }
    }

    /*
      The code below covers a use case that a TV miniplayer is closed without calling onReturn function.
      Players are paused when video is transferred to miniplayer, TV player is also paused - that is
      the only exception to TV player always playing. If miniplayer is closed in any way without resuming
      the TV player, we should force-resume it.
     */
    if (
      prevProps.isInMiniplayer &&
      !this.props.isInMiniplayer &&
      !this.props.miniplayer &&
      this.props.playerId.includes(PlayerId.TVLike) &&
      !this.state.playing
    ) {
      this.onMute(true);
      setTimeout(() => this.onPlay(), 0);
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentWillUnmount() {
    if (this.props.isInMiniplayer) {
      this.props.detachMiniplayer();
    }
  }

  render() {
    const {
      video,
      controls,
      ar,
      loop,
      wistiaOptions,
      isInMiniplayer,
      miniplayer,
      style,
      className,
    } = this.props;
    const {
      ready,
      playing,
      muted,
      volume,
      playedOnce,
      unmutedOnce,
      fullscreen,
      duration,
      PlayerComponent,
      hasError,
      playFrom,
      disabled,
    } = this.state;

    if (PlayerComponent == null) {
      return null;
    }

    const baseClassName = 'neauvia-player swiper-restrict-swipe';
    const localClassName = className
      ? `${baseClassName} ${className}`
      : baseClassName;

    if (hasError) {
      return (
        <Container $ar={ar} style={style} className={localClassName}>
          Software failure
        </Container>
      );
    }

    return (
      <Container $ar={ar} style={style} className={localClassName}>
        <ScrollHandle ref={this.scrollHandleRef} />
        <PlayerComponent
          innerRef={this.onInnerRef}
          video={video}
          playing={playing}
          fullscreen={fullscreen}
          muted={muted}
          volume={volume}
          loop={loop}
          playedOnce={playedOnce}
          playFrom={playFrom}
          controls={controls?.native?.enabled ?? true}
          miniplayer={miniplayer}
          onReady={this.onReady}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onSeek={this.onSeek}
          onEnded={this.onEnded}
          onVolumeChange={this.onVolumeChange}
          onMute={this.onMute}
          onUnmute={this.onUnmute}
          onEnterFullscreen={this.onEnterFullscreen}
          onLeaveFullscreen={this.onLeaveFullscreen}
          wistiaOptions={wistiaOptions}
        >
          {!disabled && (
            <OverlayController
              video={video}
              ready={ready}
              playing={playing}
              muted={muted}
              unmutedOnce={unmutedOnce}
              fullscreen={fullscreen}
              miniplayer={miniplayer}
              time$={this.time$}
              duration={duration}
              onPlay={this.onPlay}
              onPause={this.onPause}
              onUnmute={this.onUnmute}
              onOpenMiniplayer={this.openInMiniplayer}
              controls={controls}
            />
          )}
        </PlayerComponent>

        {isInMiniplayer && <MiniplayerInfoOverlay />}
      </Container>
    );
  }

  private static recentMutedOption?: boolean;
  private static recentVolumeOption?: number;

  private scrollHandleRef = createRef<HTMLDivElement>();
  private playerApi?: PlayerStrategyAPI;

  private time$: Observable<number, unknown> = fromPoll(
    1000,
    () => this.state.playing,
  )
    .skipDuplicates()
    .flatMapLatest((isPlaying) => {
      if (isPlaying != null) {
        const getTime = () => this.playerApi?.getRealTime() ?? 0;

        const currentTime = constant(getTime());
        return isPlaying
          ? currentTime.concat(fromPoll(300, getTime))
          : currentTime;
      } else {
        return never();
      }
    });

  // -- HANDLERS START

  private onInnerRef = (a: PlayerStrategyAPI) => (this.playerApi = a);

  private onReady = () => {
    this.setState({ ready: true });
    this.props.onReady?.(this.stateSnapshot);
  };

  private onPlay = () => {
    if (this.state.disabled) {
      return;
    }

    this.setState({ ready: true, playing: true, playedOnce: true });

    NeauviaPlayerComponent.recentMutedOption = this.state.muted;
    NeauviaPlayerComponent.recentVolumeOption = this.state.volume;

    if (!this.state.muted) {
      this.props.setAsActivePlayer();

      if (!this.props.miniplayer) {
        this.props.resetMiniplayer();
      }
    }
    this.props.onPlay?.();
  };

  private onSeek = (time: number) => {
    if (this.state.disabled) {
      return;
    }

    this.props.onSeek?.(time);
  };

  private onPause = () => {
    if (this.state.disabled) {
      return;
    }

    this.setState({ playing: false });
    this.props.onPause?.();
  };

  private onEnded = () => {
    if (this.state.disabled) {
      return;
    }

    this.setState({ playing: false });
    this.props.onEnded?.();
  };

  private onVolumeChange = (volume: number) => {
    if (this.state.disabled) {
      return;
    }

    this.setState({ volume });
    NeauviaPlayerComponent.recentVolumeOption = volume;
    savePlayerSettings(PlayerSettings.Volume, volume);
    this.props.onVolumeChange?.(volume);
  };

  private onMute = (reset?: boolean) => {
    if (this.state.disabled) {
      return;
    }

    if (reset) {
      this.setState({ muted: true, unmutedOnce: false });
    } else {
      this.setState({ muted: true });
    }
    NeauviaPlayerComponent.recentMutedOption = true;
    this.props.onMute?.();
  };

  private onUnmute = () => {
    if (this.state.disabled) {
      return;
    }

    this.setState({ muted: false, unmutedOnce: true });
    NeauviaPlayerComponent.recentMutedOption = false;

    if (this.state.playing) {
      this.props.setAsActivePlayer();

      if (!this.props.miniplayer) {
        this.props.resetMiniplayer();
      }
    }
    this.props.onUnmute?.();
  };

  private onEnterFullscreen = () => {
    if (this.state.disabled) {
      return;
    }

    this.setState({ fullscreen: true });
    this.props.onEnterFullscreen?.();
  };

  private onLeaveFullscreen = () => {
    if (this.state.disabled) {
      return;
    }

    this.setState({ fullscreen: false });
    this.props.onLeaveFullscreen?.();
  };

  private onReturnFromMiniplayer = (state: {
    playing?: boolean;
    playFrom?: number;
    muted?: boolean;
    volume?: number;
  }) => {
    this.setState((prevState) => ({
      playing: state.playing ?? prevState.playing,
      playedOnce: state.playing ?? prevState.playedOnce,
      muted: state.muted ?? prevState.muted,
      unmutedOnce: !state.muted ?? prevState.unmutedOnce,
      volume: state.volume ?? prevState.volume,
      playFrom: state.playFrom ?? prevState.playFrom,
    }));

    if (this.props.playerId.includes(PlayerId.TVLike)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.scrollHandleRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  // -- HANDLERS END
}

const usePlayerId = (props: NeauviaPlayerProps) =>
  useMemo(
    () =>
      playerIdFactory(
        props.playerId ?? PlayerId.Common,
        props.video.source?.id ?? props.video.id,
      ),
    [props.video.source?.id, props.video.id, props.playerId],
  );

const useActivePlayer = () => {
  const dispatch = useDispatch();
  const uuid = useUID();

  const isActivePlayer = useSelector(selectActivePlayerId) === uuid;

  const setAsActivePlayer = useCallback(
    () => dispatch(AppAction.setActivePlayerId(uuid)),
    [uuid, dispatch],
  );

  return [isActivePlayer, setAsActivePlayer] as const;
};

// eslint-disable-next-line react/display-name
export const NeauviaMiniplayerPlayer = memo(
  forwardRef<NeauviaPlayerAPI, NeauviaPlayerProps>((props, ref) => {
    const playerId = usePlayerId(props);
    const [isActivePlayer, setAsActivePlayer] = useActivePlayer();

    const miniplayerCurrent = useSelector(selectMiniplayerCurrent);

    return (
      <NeauviaPlayerComponent
        ref={ref as Ref<NeauviaPlayerComponent>}
        {...props}
        playerId={playerId}
        miniplayer={true}
        isInMiniplayer={false}
        isActivePlayer={isActivePlayer}
        setAsActivePlayer={setAsActivePlayer}
        openMiniplayer={noop}
        attachMiniplayer={noop}
        detachMiniplayer={noop}
        resetMiniplayer={noop}
        miniplayerInitProps={miniplayerCurrent}
      />
    );
  }),
);

// eslint-disable-next-line react/display-name
export const NeauviaPlayer = memo(
  forwardRef<NeauviaPlayerAPI, NeauviaPlayerProps>((props, ref) => {
    const store = useStore();
    const dispatch = useDispatch();

    const playerId = usePlayerId(props);
    const [isActivePlayer, setAsActivePlayer] = useActivePlayer();

    const miniplayerCurrent = useSelector(selectMiniplayerCurrent);
    const isInMiniplayer = miniplayerCurrent?.playerId === playerId;

    const openMiniplayer = useCallback(
      (state: AppActions['openMiniplayer']) => {
        props.onOpenMiniplayer?.();
        dispatch(AppAction.openMiniplayer(state));
      },
      [props, dispatch],
    );

    const attachMiniplayer = useCallback(
      (onReturn: AppActions['attachMiniplayer']) =>
        dispatch(AppAction.attachMiniplayer(onReturn)),
      [dispatch],
    );

    const detachMiniplayer = useCallback(
      () => dispatch(AppAction.detachMiniplayer({})),
      [dispatch],
    );

    const resetMiniplayer = useCallback(
      () => dispatch(AppAction.resetMiniplayer({})),
      [dispatch],
    );

    /*
      The below code should run only once on component initialization.
      It checks whether there is stored snapshot related to player that is being created.
     *
      The player must not be a miniplayer instance.
     *
      If snapshot exists and matches by playerId, it will be memoized locally,
      removed from store and passed to NeauviaPlayerComponent constructor.
     */
    const [miniplayerSnapshot] = useState(() => {
      const currentSnapshot = store.getState().miniplayer.snapshot;
      if (currentSnapshot?.playerId === playerId) {
        dispatch(AppAction.resetMiniplayer({}));
        return currentSnapshot;
      }
    });

    return (
      <NeauviaPlayerComponent
        ref={ref as Ref<NeauviaPlayerComponent>}
        {...props}
        playerId={playerId}
        miniplayer={false}
        isInMiniplayer={isInMiniplayer}
        isActivePlayer={isActivePlayer}
        setAsActivePlayer={setAsActivePlayer}
        openMiniplayer={openMiniplayer}
        attachMiniplayer={attachMiniplayer}
        detachMiniplayer={detachMiniplayer}
        resetMiniplayer={resetMiniplayer}
        miniplayerInitProps={miniplayerSnapshot}
      />
    );
  }),
);

export const NeauviaPlayerLayer = styled(NeauviaPlayer)`
  ${layer}
`;
