import { useIsomorphicLayoutEffect } from 'react-use';
import { useCallback, useEffect, useState } from 'react';
import { sessionStorage } from 'src/utils/storage';

export const useSynchronizedSessionStorage = <T>(
  key: string,
  initialValue?: T | undefined,
) => {
  const [state, setState] = useState<T | undefined>(
    () => sessionStorage.getItem(key) ?? initialValue,
  );

  useIsomorphicLayoutEffect(
    () => setState(sessionStorage.getItem(key) ?? initialValue),
    [key],
  );

  const set: (value: T | undefined) => void = useCallback(
    (newState) => {
      setState(newState);
      sessionStorage.setItem(key, newState);

      window.dispatchEvent(new CustomEvent('sessionStorageCustom'));
    },
    [key],
  );

  const remove = useCallback(() => {
    setState(undefined);
    sessionStorage.removeItem(key);

    window.dispatchEvent(new CustomEvent('sessionStorageCustom'));
  }, [key]);

  useEffect(() => {
    const updateValue = () => {
      setState(sessionStorage.getItem(key) ?? initialValue);
    };

    window.addEventListener('sessionStorageCustom', updateValue);
    return () => {
      window.removeEventListener('sessionStorageCustom', updateValue);
    };
  }, [initialValue, key]);

  return [state, set, remove] as const;
};
