import { useTranslate } from 'react-t7e';
import React, { useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  playerFadeZIndex,
  OverlayLayer,
  playerOverlayAboveControlsZIndex,
} from 'src/components/player/common';
import { makeTransition } from 'src/utils/motion/transitions';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme, dirRTL, fromTheme } from 'src/styles/theme';
import { CenteredContent } from 'src/components/common/Layers';
import { CheckboxFormik } from 'src/components/common/Checkbox';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { Button } from 'src/components/common/Button';
import styled from 'styled-components';
import { layer } from 'src/styles/common';
import { transparentize } from 'polished';
import { media } from 'src/styles/designs';
import { useUserHealthcareLegalConsent } from 'src/hooks/useUserHealthcareLegalConsent';
import { useRegistrationFormLegalNoticesQuery } from 'src/hooks/consents/useRegistrationFormLegalNoticesQuery';

export const Fade = motion(styled.div`
  ${layer};
  z-index: ${playerFadeZIndex};
  background: ${({ theme }) => transparentize(0.15, theme.palette.background)};
`);

const ContentHeader = styled.div`
  font-weight: 300;
  font-size: 21px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${fromTheme((t) => t.text.palette.heavy)};
`;

const ConsentBlock = styled(motion.div)`
  display: grid;
  grid-template-areas: 'header' 'consent' 'button';
  width: 271px;
  gap: 30px;
  white-space: normal;

  ${media.w.lessThan.desktop} {
    width: 371px;
    --base-unit: 100vw / 560;
  }
`;

const StyledOverlayLayer = styled(OverlayLayer)`
  z-index: ${playerOverlayAboveControlsZIndex};

  ${dirRTL} {
    direction: rtl;
  }
`;

const StyledCenteredContent = styled(CenteredContent)`
  z-index: ${playerFadeZIndex + 1};
`;

export interface HealthcareLegalConsentOverlayProps {
  onPlay: () => void;
  onUnmute: () => void;
  backgroundOnly?: boolean;
  onBackgroundClick?: () => void;
}

export const HealthcareLegalConsentOverlay = ({
  onPlay,
  onUnmute,
  backgroundOnly,
  onBackgroundClick,
}: HealthcareLegalConsentOverlayProps) => {
  const { _ } = useTranslate();

  const { healthcareProfessionalKey, setSessionConsents, userConsentValue } =
    useUserHealthcareLegalConsent();

  const saveConsent = useCallback(() => {
    setSessionConsents(true);
    onPlay();
    onUnmute();
  }, [onPlay, onUnmute, setSessionConsents]);

  const legalNotices = useRegistrationFormLegalNoticesQuery();
  const notice = legalNotices.data?.find(
    (t) => t.identifier === healthcareProfessionalKey,
  );

  return (
    <AnimatePresence initial={false}>
      {notice && !userConsentValue ? (
        <Formik
          onSubmit={saveConsent}
          validationSchema={Yup.object({
            [healthcareProfessionalKey]: Yup.boolean()
              .isTrue(_('Required'))
              .required(_('Required')),
          })}
          initialValues={{
            [healthcareProfessionalKey]: userConsentValue,
          }}
        >
          <Form>
            <StyledOverlayLayer
              {...makeTransition({
                fade: true,
              })}
              as={motion.div}
            >
              <Theme theme={darkTheme}>
                <Fade onClick={onBackgroundClick} />
                {!backgroundOnly && (
                  <StyledCenteredContent
                    {...makeTransition({
                      from: 'top',
                      to: 'bottom',
                    })}
                  >
                    <ConsentBlock className="player__consent-block" layout>
                      <ContentHeader>
                        {_(
                          'MEDICAL CONTENT CONFIRMATION OF PROFESSION REQUIRED',
                        )}
                      </ContentHeader>
                      <CheckboxFormik name={healthcareProfessionalKey}>
                        <Markdown>{notice.content}</Markdown>
                      </CheckboxFormik>
                      <Button type="submit">{_('CONFIRM AND WATCH')}</Button>
                    </ConsentBlock>
                  </StyledCenteredContent>
                )}
              </Theme>
            </StyledOverlayLayer>
          </Form>
        </Formik>
      ) : null}
    </AnimatePresence>
  );
};
