import React, { FC } from 'react';
import { TranslatedItem } from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { ShortcodeLink } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeLink';
import { ShortcodeContact } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeContact';
import { ShortcodeLabel } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeLabel';
import { ShortcodeUpcomingVideo } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeUpcomingVideo';
import { ShortcodeVideo } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeVideo';
import { ShortcodeProducts } from 'src/components/player/overlays/ShortcodesOverlay/ShortcodeProducts';

export const ShortcodeDiscriminator: FC<{
  shortcode: TranslatedItem<Videos_Shortcodes>;
}> = ({ shortcode }) => {
  switch (shortcode.type) {
    case 'link':
      return <ShortcodeLink shortcode={shortcode} />;
    case 'video':
      return <ShortcodeVideo shortcode={shortcode} />;
    case 'contact':
      return <ShortcodeContact shortcode={shortcode} />;
    case 'label':
      return <ShortcodeLabel shortcode={shortcode} />;
    case 'upcoming':
      return <ShortcodeUpcomingVideo shortcode={shortcode} />;
    case 'products':
      return <ShortcodeProducts shortcode={shortcode} />;
    default:
      return null;
  }
};
