import { useCallback } from 'react';
import { useSynchronizedSessionStorage } from 'src/hooks/useSynchronizedSessionStorage';

export const useUserMdrDisclaimer = () => {
  const [confirmed, setConfirmed] =
    useSynchronizedSessionStorage('nc_mdr-disclaimer');

  const onConfirm = useCallback(() => {
    setConfirmed(true);
  }, [setConfirmed]);

  return { confirmed, onConfirm };
};
