import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { shortcodeCommonLayout } from 'src/components/player/overlays/ShortcodesOverlay/common';
import { TranslatedItem } from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { useTranslate } from 'react-t7e';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme } from 'src/styles/theme';
import { makeTransition } from 'src/utils/motion/transitions';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { Button } from 'src/components/common/Button';
import { Close } from 'src/components/icons/Close';
import React from 'react';
import { CloseModalWrapper } from 'src/modules/modals/components/common';
import { Thumbnail } from 'src/modules/materials/components/Thumbnail';
import { MediaDirect } from 'vendor/mods/media-breakpoints-fresnel/components/Media';
import { media } from 'src/styles/designs';

const ShortcodeVideoContainer = motion(styled.div<{
  $location: string | 'left' | 'right';
}>`
  ${shortcodeCommonLayout};
  display: grid;
  grid-template-areas: 'header close' 'content content';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: row;
  grid-gap: 18px;
  text-transform: none;
  width: 264px;
  box-sizing: content-box;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.text.palette.primary};
  padding: 22px 28px 28px;

  ${({ $location }) =>
    $location === 'left'
      ? css`
          background: linear-gradient(
            -90deg,
            rgba(16, 16, 16, 0.7) 23.32%,
            rgba(47, 47, 47, 0) 134.98%
          );
        `
      : css`
          background: linear-gradient(
            270deg,
            rgba(16, 16, 16, 0.7) 23.32%,
            rgba(47, 47, 47, 0) 134.98%
          );
        `}

  backdrop-filter: blur(12px);

  ${media.w.lessThan.desktop} {
    width: 306px;
    grid-template-columns: 1fr 30px;
    grid-template-areas: 'header space' 'content space';
    padding: 8px;
    font-size: 10px;
    line-height: 12px;
    grid-gap: 7px;
  }
`);

const CloseButton = styled(Button)`
  grid-area: close;
  position: relative;
  font-size: 10px !important;

  ${media.w.lessThan.desktop} {
    position: absolute !important;
    top: 25px;
    right: 14px;

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
`;

const Header = styled.div`
  grid-area: header;
`;

const Content = styled.div`
  grid-area: content;
`;

export function ShortcodeVideo(props: {
  shortcode: TranslatedItem<Videos_Shortcodes>;
}) {
  const { _ } = useTranslate();

  const { location, video_video } = props.shortcode;
  return (
    <Theme theme={darkTheme}>
      <ShortcodeVideoContainer
        layout
        {...makeTransition({
          fade: true,
          from: location === 'left' ? 'left' : 'right',
          speed: 'slow',
        })}
        $location={location}
        kind="text"
      >
        <Header>
          <Markdown>{_('Combined video', 'shortcodes')}</Markdown>
        </Header>
        <CloseModalWrapper>
          <CloseButton kind="text" icon={Close} />
        </CloseModalWrapper>
        <Content>
          <MediaDirect greaterThanOrEqual="desktop">
            <Thumbnail
              item={video_video}
              showUserActions={false}
              showLock={false}
            />
          </MediaDirect>
          <MediaDirect lessThan="desktop">
            <Thumbnail
              item={video_video}
              variant="small"
              showUserActions={false}
            />
          </MediaDirect>
        </Content>
      </ShortcodeVideoContainer>
    </Theme>
  );
}
