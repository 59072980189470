import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { shortcodeCommonLayout } from 'src/components/player/overlays/ShortcodesOverlay/common';
import { TranslatedItem } from 'src/hooks/directus/useTranslateItem';
import { Videos_Shortcodes } from 'src/graphql/schema.graphql';
import { useTranslate } from 'react-t7e';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme } from 'src/styles/theme';
import { makeTransition } from 'src/utils/motion/transitions';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { Button, ButtonContainer } from 'src/components/common/Button';
import { Close } from 'src/components/icons/Close';
import React from 'react';
import { media } from 'src/styles/designs';
import { CloseModalWrapper } from 'src/modules/modals/components/common';
import { DispatchOnClick } from 'src/components/common/DispatchOnClick';

const ShortcodeContactContainer = motion(styled.div<{
  $location: string | 'left' | 'right';
}>`
  ${shortcodeCommonLayout};
  display: grid;
  grid-template-areas: 'header close' 'content content';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: row;
  grid-gap: 18px;
  text-transform: none;

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.text.palette.primary};
  padding: 22px 28px 28px;

  ${({ $location }) =>
    $location === 'left'
      ? css`
          background: linear-gradient(
            -90deg,
            rgba(16, 16, 16, 0.7) 23.32%,
            rgba(47, 47, 47, 0) 134.98%
          );
        `
      : css`
          background: linear-gradient(
            270deg,
            rgba(16, 16, 16, 0.7) 23.32%,
            rgba(47, 47, 47, 0) 134.98%
          );
        `}
  backdrop-filter: blur(12px);

  ${media.w.lessThan.desktop} {
    font-size: 10px;
    line-height: 12px;
    grid-template-areas: 'header' 'content';
    grid-template-columns: 1fr;
    padding: 13px 20px 16px;
    grid-gap: 13px;

    ${ButtonContainer} {
      min-width: 157px;
    }
  }
`);

const CloseButton = styled(Button)`
  grid-area: close;
  position: relative;
  font-size: 10px !important;

  ${media.w.lessThan.desktop} {
    display: none;
  }
`;

const Header = styled.div`
  grid-area: header;
`;

const Content = styled.div`
  grid-area: content;
`;

export function ShortcodeContact(props: {
  shortcode: TranslatedItem<Videos_Shortcodes>;
}) {
  const { _ } = useTranslate();

  const { location } = props.shortcode;
  return (
    <Theme theme={darkTheme}>
      <ShortcodeContactContainer
        layout
        {...makeTransition({
          fade: true,
          from: location === 'left' ? 'left' : 'right',
          speed: 'slow',
        })}
        $location={location}
        kind="text"
      >
        <Header>
          <Markdown>{_('Contact our Neauvia expert')}</Markdown>
        </Header>
        <CloseModalWrapper>
          <CloseButton kind="text" icon={Close} />
        </CloseModalWrapper>
        <Content>
          <DispatchOnClick type="modalOpen" payload={{ kind: 'contact' }}>
            <Button as="a" variant="primary" target="_blank">
              {_('contact me')}
            </Button>
          </DispatchOnClick>
        </Content>
      </ShortcodeContactContainer>
    </Theme>
  );
}
