import dynamic from 'next/dynamic';
import { VideoSources } from 'src/graphql/schema.graphql';
import { ComponentType } from 'react';
import { PlayerStrategyProps } from 'src/components/player/playerStrategy/types';
import { VideoFormat } from 'src/modules/videos/models';

const WistiaPlayerStrategy = dynamic(
  () => import('src/components/player/playerStrategy/WistiaPlayerStrategy'),
);

const ReactPlayerStrategy = dynamic(
  () => import('src/components/player/playerStrategy/ReactPlayerStrategy'),
);

const SourcelessPlayerStrategy = dynamic(
  () => import('src/components/player/playerStrategy/SourcelessPlayerStrategy'),
);

export const playerStrategyProvider = (
  source?: VideoSources,
): ComponentType<PlayerStrategyProps> => {
  if (source == null) {
    return SourcelessPlayerStrategy;
  }

  if (source.format === VideoFormat.LiveHLS) {
    return ReactPlayerStrategy;
  }

  return WistiaPlayerStrategy;
};
