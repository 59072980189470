import styled from 'styled-components';
import { dirRTL } from 'src/styles/theme';
import { useTranslate } from 'react-t7e';
import { Button } from 'src/components/common/Button';
import { motion } from 'framer-motion';
import { media } from 'src/styles/designs';
import { OverlayLayer } from 'src/components/player/common';
import { Close } from 'src/components/icons/Close';
import { Talks } from 'src/components/icons/Talks';
import { useToggle } from 'react-use';
import { DispatchOnClick } from 'src/components/common/DispatchOnClick';

const Wrapper = styled.div`
  position: absolute;
  bottom: 50px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  ${media.w.greaterThanOrEqual.desktop} {
    right: 40px;
  }
`;

const ToggleContactInfoButton = styled(Button)`
  pointer-events: all;

  ${media.w.greaterThanOrEqual.desktop} {
    padding: 5px;
  }
`;

const StyledContactInfoWrapper = styled(motion.div)`
  width: 185px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  padding: 21px 16px 16px 16px;
  gap: 16px;
  color: ${({ theme }) => theme.text.palette.secondary};
`;

const ContactButton = styled(Button)`
  pointer-events: all;
  min-width: 100% !important;
  border-color: ${({ theme }) => theme.palette.accent} !important;
`;

const CallToActionText = styled.span`
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;

  ${dirRTL} {
    text-align: right;
  }
`;

export const ContactButtonOverlay = () => {
  const [contactInfoVisible, toggleContactInfoVisible] = useToggle(false);

  const { _ } = useTranslate();

  return (
    <OverlayLayer>
      <Wrapper>
        {contactInfoVisible && (
          <StyledContactInfoWrapper
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{
              opacity: 0,
            }}
          >
            <CallToActionText>
              {_('Contact our Neauvia expert')}
            </CallToActionText>
            <DispatchOnClick type="modalOpen" payload={{ kind: 'contact' }}>
              <ContactButton
                size="small"
                variant="secondary"
                onClick={toggleContactInfoVisible}
              >
                {_('contact me')}
              </ContactButton>
            </DispatchOnClick>
          </StyledContactInfoWrapper>
        )}

        <ToggleContactInfoButton
          forwardedAs="a"
          kind="circled"
          icon={contactInfoVisible ? Close : Talks}
          iconProps={{
            width: 20,
            height: 20,
          }}
          onClick={toggleContactInfoVisible}
        />
      </Wrapper>
    </OverlayLayer>
  );
};
