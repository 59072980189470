import { useTranslate } from 'react-t7e';
import { motion } from 'framer-motion';
import {
  playerFadeZIndex,
  OverlayLayer,
  playerOverlayAboveControlsZIndex,
} from 'src/components/player/common';
import { makeTransition } from 'src/utils/motion/transitions';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme, dirRTL } from 'src/styles/theme';
import { Markdown } from 'vendor/mods/markdown/components/Markdown';
import { FullWidthButton } from 'src/components/common/Button';
import styled from 'styled-components';
import { ShadowedScrollableContent } from 'src/components/ScrollableContent';
import { Fade } from 'src/components/player/overlays/HealthcareLegalConsentOverlay';
import { useUserMdrDisclaimer } from 'src/hooks/useUserMdrDisclaimer';
import { Videos } from 'src/graphql/schema.graphql';
import { useEffect } from 'react';
import { media } from 'src/styles/designs';

export interface MdrDisclaimerOverlayProps {
  video: Videos;
  onUnmute: () => void;
  onPlay: () => void;
  onPause: () => void;
}

const Container = styled(ShadowedScrollableContent)`
  position: relative;

  .simplebar-content-wrapper {
    display: flex;
    height: 100% !important;
    & > div {
      margin: auto;
    }
  }

  z-index: ${playerFadeZIndex + 1};
  width: 100%;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
`;

const Description = styled(motion.div)`
  width: 100%;
  font-size: 300;
  font-size: 11px;
  line-height: 18px;

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledOverlayLayer = styled(OverlayLayer)`
  display: flex;
  z-index: ${playerOverlayAboveControlsZIndex};
  ${dirRTL} {
    direction: rtl;
  }
`;

const ContentContainer = styled(motion.div)`
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 32px 32px;

  max-width: 570px;
  color: ${({ theme }) => theme.text.palette.primary};
`;

export const MdrDisclaimerOverlay = ({
  video,
  onUnmute,
  onPlay,
  onPause,
}: MdrDisclaimerOverlayProps) => {
  const { _ } = useTranslate();

  const mdrDisclaimer = useUserMdrDisclaimer();

  const visible = !mdrDisclaimer.confirmed && video.disclaimer;

  const onConfirm = () => {
    mdrDisclaimer.onConfirm();
    onUnmute();
    onPlay();
  };

  useEffect(() => {
    if (visible) {
      onPause();
    }
  }, [onPause, visible]);

  if (!visible) {
    return null;
  }

  return (
    <StyledOverlayLayer
      {...makeTransition({
        fade: true,
      })}
      as={motion.div}
    >
      <Theme theme={darkTheme}>
        <Fade />
        <Container>
          <ContentContainer
            {...makeTransition({
              from: 'top',
              to: 'bottom',
            })}
          >
            <Heading>{_('video.disclaimer.title')}</Heading>
            {/* TODO: Temporary disabling arabic rtl direction, because we don't have a translation and the text here is English. */}
            {/* https://thrnd.atlassian.net/jira/software/c/projects/NEAUV/boards/55?selectedIssue=NEAUV-2415 */}
            <Description style={{ direction: 'ltr' }}>
              <Markdown>{_('video.disclaimer.text')}</Markdown>
            </Description>
            <FullWidthButton onClick={onConfirm}>
              {_('CONFIRM AND WATCH')}
            </FullWidthButton>
          </ContentContainer>
        </Container>
      </Theme>
    </StyledOverlayLayer>
  );
};
