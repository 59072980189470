import { IconBox, IconProps } from '@summer/jst-react-icon';

export const Expand = (props: Omit<IconProps, 'ref'>) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 59H58V7H34V11H54V55H10V35H6V59ZM10 13.8285V27H6V7H26V11H12.8284L29.4951 27.6667L26.6667 30.4951L10 13.8285Z"
    />
  </IconBox>
);
