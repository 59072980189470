import { useQuery } from 'react-query';
import { QueryKey } from 'src/models/query';
import { useAPIs } from 'src/hooks/useAPIs';
import { UseQueryOptions } from 'react-query/types/react/types';
import { longStaleQueryBase } from 'src/utils/queryCommonOptions';
import { LegalNotice } from 'src/services/identityExtensions/common';

export const useRegistrationFormLegalNoticesQuery = () => {
  const apis = useAPIs();
  return useQuery({
    ...longStaleQueryBase,
    queryKey: QueryKey.RegistrationLegalNotices,
    queryFn: () => apis.identityExtensions.legal.notices.registration(),
  } as UseQueryOptions<LegalNotice[]>);
};
