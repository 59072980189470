import React, { memo } from 'react';
import { OverlayLayer } from 'src/components/player/common';
import styled from 'styled-components';
import { fromTheme } from 'src/styles/theme';
import { Videos } from 'src/graphql/schema.graphql';
import { makeTransition } from 'src/utils/motion/transitions';
import { motion } from 'framer-motion';
import { VideoSummary } from 'src/modules/videos/components/VideoSummary';

const VideoSummaryContainer = styled(motion.div)`
  opacity: 0;
  width: 220px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 50px 30px;
  transition: all ${fromTheme((t) => t.animation.duration / 2)}s
    ${fromTheme((t) => t.animation.easing.accelerated)};
  transform: translateX(-100%);
`;

export interface VideoSummaryOverlayProps {
  video: Videos;
}

export const VideoSummaryOverlay = memo<VideoSummaryOverlayProps>(
  function VideoSummaryOverlay({ video }) {
    return (
      <OverlayLayer>
        <VideoSummaryContainer
          {...makeTransition({ fade: true, speed: 'faster' })}
          className="np-overlay__video-summary"
        >
          <VideoSummary video={video} moreButton={false} />
        </VideoSummaryContainer>
      </OverlayLayer>
    );
  },
);
