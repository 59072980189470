import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { ellipsis } from 'polished';
import { Avatar } from 'src/modules/user/components/Avatar';
import { People } from 'src/graphql/schema.graphql';
import { useTranslateItem } from 'src/hooks/directus/useTranslateItem';

const Container = styled.div`
  display: grid;
  grid-template-areas: 'avatar name' 'avatar title';
  grid-template-columns: 28px 1fr;
  grid-column-gap: 10px;
  align-items: center;
  grid-row-gap: 3px;
`;

const StyledAvatar = styled(Avatar)`
  grid-area: avatar;
  min-width: 0;
  width: 100%;
`;

const Name = styled.span`
  grid-area: name;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  min-width: 0;
  ${ellipsis('100%')};
  align-self: flex-end;
`;

const Title = styled.span`
  grid-area: title;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  min-width: 0;
  ${ellipsis('100%')};
  align-self: flex-start;
`;

export interface SpeakerCardProps {
  speaker: People;
}

export const SpeakerCard: FC<SpeakerCardProps> = ({ speaker }) => {
  const translateItem = useTranslateItem();
  const preparedSpeaker = useMemo(
    () => ({
      ...translateItem(speaker),
      country: speaker.country
        ? translateItem(speaker.country)?.name
        : undefined,
    }),
    [speaker],
  );

  return (
    <Container>
      <StyledAvatar src={preparedSpeaker.avatar?.id} />
      <Name>
        {preparedSpeaker.degree ? `${preparedSpeaker.degree} ` : ''}
        {preparedSpeaker.name}
      </Name>
      <Title>
        {preparedSpeaker.title}
        {preparedSpeaker.country ? ` - ${preparedSpeaker.country}` : ''}
      </Title>
    </Container>
  );
};
