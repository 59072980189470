import { memo } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Theme } from 'vendor/mods/styled-components';
import { darkTheme } from 'src/styles/theme';
import { Expand } from 'src/components/icons/Expand';
import { Close } from 'src/components/icons/Close';
import { Button } from 'src/components/common/Button';
import { OverlayLayer } from 'src/components/player/common';

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;
  color: ${({ theme }) => theme.text.palette.primary};

  position: absolute;
  top: 12px;
  right: 12px;
  left: 12px;

  opacity: 0;
  transition: 0.15s;
`;

export interface MiniplayerActionsOverlayProps {
  onReturn: () => void;
  onClose: () => void;
}

// eslint-disable-next-line react/display-name
export const MiniplayerActionsOverlay = memo(
  ({ onReturn, onClose }: MiniplayerActionsOverlayProps) => (
    <Theme theme={darkTheme}>
      <OverlayLayer css="z-index: 15">
        <Container className="player__miniplayer-actions-overlay">
          <Button
            kind="circled"
            size="small"
            icon={Expand}
            onClick={onReturn}
          />
          <Button kind="circled" size="small" icon={Close} onClick={onClose} />
        </Container>
      </OverlayLayer>
    </Theme>
  ),
);
