import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'src/styles/designs';
import { Button } from 'src/components/common/Button';
import { cloneElement, FC, ReactElement, useContext } from 'react';
import { ModalControllerContext } from 'src/components/common/ModalControllerContext';

export const ModalInner = styled(motion.div)`
  display: grid;
  position: absolute;
  top: 0;
  // For use in fullscreen modals to prevent layout shifting in browsers without overlay scrollbar
  left: calc(var(--scrollbar-padding) * -1);
  right: 0;
  bottom: 0;

  grid-template-columns: 1fr 400px 1fr;
  grid-template-rows: 1fr min-content 1fr;
  grid-template-areas:
    '. . .'
    '. content .'
    '. . .';

  ${media.w.lessThan.desktop} {
    position: relative;
    height: 100%;
    left: 0;
    grid-template-columns: 1fr;
    grid-template-rows: min-content minmax(0, 1fr);
    grid-template-areas:
      'header'
      'content';
    background: ${({ theme }) => theme.palette.background};
  }
`;

export const FormArea = styled.div`
  grid-area: content;
  margin-bottom: auto;
  margin-top: auto;
  max-height: 100%;
  height: 100%;
`;

export const FormPadding = motion(styled.div`
  padding: 10px;

  ${media.w.lessThan.desktop} {
    padding: 20px;
  }
`);

export const CloseModalButton = styled(Button)`
  && {
    position: absolute;
    right: 10vw;
    top: 9vh;
  }
`;

export const CloseModalWrapper: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const close = useContext(ModalControllerContext).close;
  return close ? <>{cloneElement(children, { onClick: close })}</> : null;
};
