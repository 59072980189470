import { useSynchronizedLocalStorage } from 'src/hooks/useSynchronizedLocalStorage';
import { useUserQuery } from 'src/modules/user/hooks/useUserQuery';

/**
 * userConsentValue: Returns true if user has accepted "healthcareProfessional"
 * consent via session checkbox or in registration form.
 */
export function useUserHealthcareLegalConsent() {
  const healthcareProfessional =
    useUserQuery().data?.identity.traits.preferencesSetter
      .healthcareProfessional;
  const [sessionConsents, setSessionConsents] =
    useSynchronizedLocalStorage<boolean>('healthcareProfessional', false);

  const userConsentValue = healthcareProfessional || sessionConsents;

  return {
    healthcareProfessionalKey: 'healthcareProfessional',
    setSessionConsents,
    userConsentValue,
  };
}
